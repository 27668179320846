@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
* {
  text-decoration: none !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Lato", "Open Sans",
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --background-color: #eef2f6;
  --text-color-dark: #262f3e;
  --prime-color: #001744;
  background-color: var(--background-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
